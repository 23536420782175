import React from 'react'
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid"
import Whatsapp from '../assets/whatsapp.svg'

const Footer = () => {
  return (
    <div>
         <div className='bg-dark-blue hidden md:block'>
            <div className='container-screen grid grid-cols-3 pt-3'>
                <div>
                    <p className='font-sairaCondense text-white text-2xl'>CONTACT US</p>

                    <div className='flex my-3'>
                    <EnvelopeIcon className='w-6 text-white ' />
                        <p className='ml-2  text-white'>aryndomulyasakti@gmail.com</p>
                    </div>
                    <div className='flex mt-3'>
                        <PhoneIcon className='w-5 text-white ' />
                        <p className='ml-3  text-white'>021-55964853</p>
                    </div>

                    <div className='flex my-3'>
                        <img src={Whatsapp} alt='WhatsApp' className='w-6 h-6' />
                        <p className='ml-2  text-white'>+62 812-9421-3544 <span className='font-semibold text-white'>( Maya / Dewi )</span></p>
                    </div>              
                </div>

                <div>
                    <p className='font-sairaCondense text-white text-2xl'>ADDRESS</p>

                <p className='text-white mt-2'>Komplek Perkantoran PIK, B-20 <br/>Kapuk, Jakarta Utara</p>             
                </div>
                <div className='flex items-end justify-end pb-2'>
                    <p className='text-white font-thin text-xs'><span className='font-semibold'> © PT ARYNDO MULYA SAKTI </span>2023</p> 
                </div>
            </div>
        </div>


        <div className='bg-dark-blue block sm:hidden'>
            <div className='container-screen grid grid-cols-1 pt-3'>
                <div>
                    <p className='font-sairaCondense text-white text-sm'>CONTACT US :</p> 
                    <div className='flex mt-1'>
                        <EnvelopeIcon className='w-3 text-white ' />
                        <p className='ml-2  text-white text-xs'>aryndomulyasakti@gmail.com</p>
                    </div>      
                    <div className='flex mt-1'>
                        <PhoneIcon className='w-3 text-white ' />
                        <p className='ml-2  text-white text-xs'>021-55964853</p>
                    </div>

                    <div className='flex my-1'>
                        <img src={Whatsapp} alt='WhatsApp' className='w-3 h-3' />
                        <p className='ml-2  text-white text-xs'>+62 812-9421-3544 <span className='font-semibold text-white'>( Maya / Dewi )</span></p>
                    </div>                
                </div>     

                <div>
                    <p className='font-sairaCondense text-white text-sm mt-3'>ADDRESS :</p>

                <p className='text-white mt-1 text-xs'>Komplek Perkantoran PIK, B-20 <br/>Kapuk, Jakarta Utara</p>             
                </div>
                <div className='flex items-center justify-end pb-2'>
                    <p className='text-white font-thin text-xxs mt-10'><span className='font-semibold'> © PT ARYNDO MULYA SAKTI </span>2023</p> 
                </div>
            </div>
        </div>
    </div>
   
  )
}

export default Footer