import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Body, ProductCard } from '../component';
import HomeImage from '../assets/Dow-Chemical-plant-South-Charleston-West-Virginia.jpg';
import BodyImage from '../assets/bodyImage.jpg'
import Alamat from '../assets/alamat.jpg'
import Sky from '../assets/sky.avif'


const Home = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${HomeImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '40%',
    backgroundAttachment: 'scroll',
  };

  const backgroundImageStyleSmall = {
    backgroundImage: `url(${Sky})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '10%',
    backgroundAttachment: 'scroll',
  };

  const backgroundImageSecond = {
    backgroundImage: `url(${BodyImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '20%',
    backgroundAttachment: 'scroll',

  }

  return (
    <div>
      <Navbar />
      {/* Banner Section */}
      <section className='h-52 sm:block hidden sm:h-96 bg-dark-blue bg-fixed' style={backgroundImageStyle}>
        <div className='hidden sm:flex w-1/3 h-1/2 text-white font-sairaCondense text-6xl container-screen justify-end items-end'>
          <p className='text-left'>BRING A<br/>BRIGHTER FUTURE</p>
        </div> 
      </section>
      <section className='h-52 sm:hidden block sm:h-96 bg-dark-blue bg-fixed' style={backgroundImageStyleSmall}>
      <div className='pt-6 text-center container-screen'> 
          <p className=' text-white font-sairaCondense sm:text-left text-5xl '>BRING A<br/><span className='text-dark-blue font-sairaCondense sm:text-left text-5xl'>BRIGHTER </span><br/>FUTURE</p>
        </div>
      </section>
      <div>
      <Body
        subHeader= "ABOUT US"
        firstParagraph="PT. Aryndo Mulya Sakti adalah perusahaan yang berdiri sejak tahun 2001 di Indonesia. Produk unggulan yang kami jual dapat berikan adalah formalin serta belerang. Perusahaan kami bergerak di lebih dari satu bidang yang melingkupi: kimia industri/makanan (industrial/food chemical), sistem timbang (weighing system), manufaktur bumbu (seasoning manufacturer), sistem pencahayaan LED (LED lighting system), dan food trading supplier."
        secondParagraph="Komitmen kami adalah memberikan kontribusi yang terbaik bagi relasi, pelanggan, karyawan, masyarakat dan bangsa kami yang tercinta Indonesia. Kami akan terus bergerak maju dan aktif dalam inovasi dalam mengembangkan perusahaan menuju perusahaan yang terbaik dibidangnya."
        thirdParagraph="Kami percaya Tuhan yang menuntun dan menyertai perjalanan perusahaan ke tempat yang ditentukanNya."
      />
      </div>
      <section  style={backgroundImageSecond} >
        <div className='container-screen pb-4'>
            <div className='pt-3'>
              <p className='sub-header-right text-white border-r-white'>OUR PRODUCTS</p>
            </div>
            <div>
              <ProductCard />   
            </div>
            <div className='flex sm:justify-end justify-center'>
              <Link to={"/other"} className='ml-full font-sairaCondense text-slate-300 mt-2 hover:text-white  transition duration-300'>OTHER PRODUCTS</Link>
            </div>
            
          </div>      
      </section>

      <section>
        <div className='container-screen'>
          <p className='sub-header-left text-dark-blue border-r-white'>WHERE TO FIND US</p>
          <p className='body-text'>Komplek Pekantoran PIK, B-20, <br/>Kapuk, Jakarta Utara</p>
          <div className='flex items-center justify-center'>

            <img 
            src={Alamat}
            className='mt-3'
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
