import React from 'react'


const Banner = (props) => {

  return (
    <div className='h-[10rem] sm:h-[15rem] flex items-center bg-right bg-cover' style={props.background}>
        <div className='container-screen flex flex-col items-start'>
            <p className={props.clasname}>{props.name}</p>
            <p className={props.clasname}>{props.name2}</p>
            <p className='text-white  italic'>{props.latin}</p>      
        </div>
    </div>
  )
}

export default Banner