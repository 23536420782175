import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon,  XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import Logo from '../assets/logo ams bolong hd.png'

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Products', href: '/formalin', current: false },
  { name: 'Contact', href: '/contact', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl container-screen">
            <div className="flex h-16">
                <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-12 w-auto lg:hidden"
                      src={ Logo }
                      alt="Your Company"
                    />
                    <img
                      className="hidden h-12 w-auto lg:block"
                      src={ Logo }
                      alt="Your Company"
                    />
                  </div>
                  <div className='flex justify-center items-center'>
                    <p className='font-inriaSerif ml-2 text-slate-400 sm:text-sm text-xs mt-2'>Since 2001</p>
                  </div>
                  <div className="flex ml-auto items-center md:hidden">
                    <div>
                        {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-blue-950 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>                
                  </div>
                
                <div className="hidden md:ml-auto md:flex  md:items-center md:space-x-[90px] ">
                  {navigation.map((item) => (
                  item.name === "Products" ? (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-inriaSerif text-slate-400 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                          Products
                          <ChevronDownIcon
                            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/formalin"
                                  className={`${
                                    active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-600 font-semibold'
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Formalin
                                </Link>
                              )}
                            </Menu.Item>
                           
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/benzalkonium"
                                  className={`${
                                    active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-600 font-semibold'
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Benzalkonium Chloride
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/citric"
                                  className={`${
                                    active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-600 font-semibold'
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Citric Acid Monohydrate
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/belerang"
                                  className={`${
                                    active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-600 font-semibold'
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Belerang
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/other"
                                  className={`${
                                    active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-300 font-semibold'
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Other Products
                                </Link>
                              )}
                            </Menu.Item>
                           
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                   ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-slate-400 hover:text-dark-blue font-inriaSerif font-bold animation duration-300',
                        'px-3 py-2 rounded-md text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Link>
                   )
                    
                  ))}
                </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden container-screen">
            <div className="space-y-1 pt-2 pb-3">
              {navigation.map((item) => (
              item.name === "Products" ? (
                <Menu>
                  <div >
                    <Menu.Button>
                      <div className='flex font-semibold text-slate-500 hover:text-dark-blue'>
                      {item.name}
                      <ChevronDownIcon
                              className="ml-3 mt-1 h-3 w-3 text-slate-600 hover:text-dark-blue"
                              aria-hidden="true"
                            />
                      </div>
                  
                    </Menu.Button>
                    
                  </div>                
                  <Menu.Items>
                    <div>
                      <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-400 font-semibold'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to="/formalin"
                        >
                          Formalin
                        </Link>
                      )}
                      </Menu.Item>

                      <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-400 font-semibold'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to="/benzalkonium"
                        >
                          Benzalkonium Chloride
                        </Link>
                      )}
                      </Menu.Item>
                      <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-400 font-semibold'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to="/citric"
                        >
                          Citric Acid Monohydrate
                        </Link>
                      )}
                      </Menu.Item>
                      <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-400 font-semibold'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to="/belerang"
                        >
                          Belerang
                        </Link>
                      )}
                      </Menu.Item>

                      <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? 'bg-dark-blue text-white font-semibold' : 'text-gray-300 font-semibold'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to="/other"
                        >
                          Other Products
                        </Link>
                      )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
              ):(
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-slate-500 hover:text-dark-blue animation duration-200',
                    'block py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              )
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


