import React from 'react'
import { Navbar, Banner, Body } from '../component'
import Bannerf from '../assets/banner_benzalkonium.jpg'
import Lab from '../assets/lab.png'

const Benzalkonium = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Bannerf})`,
  };
  
  
const application = [
  {
    title: "1. WATER TREATMENT:",
    text: "Digunakan sebagai bakterisida, membunuh alga hijau, bercak hitam, dan alga mustard."
  },
  {
    title: "2. DETERGENT:",
    text: "Bahan baku deterjen mentah."
  },
  {
    title: "3. ADDITIVE:",
    text: "Umumnya digunakan sebagai tambahan makanan, pertambangan, penyamakan kulit, pupuk, pelapisan logam (elektroplating), pewarnaan, pencetakan, pengecoran presisi, dan lain sebagainya."
  },
  {
    title: "4. OIL & GAS INDUSTRY:",
    text: "Kemampuan biokida dan algaesida yang kuat, digunakan untuk mencegah pipa dari penyumbatan dan pengkaratan."
  },
  {
    title: "5. MEDICINE:",
    text: "Klorida benzalkonium adalah pengawet yang sering digunakan dalam tetes mata; konsentrasi tipikal berkisar antara 0,004% hingga 0,01%. Konsentrasi yang lebih kuat dapat bersifat kaustik dan menyebabkan kerusakan ireversibel pada endotel kornea."
  },
  
]
  return (
    <div>
        <Navbar />
        <Banner background={backgroundImageStyle} name="BENZALKONIUM" name2="CHLORIDE" clasname="font-sairaCondense text-white text-3xl sm:text-6xl" latin="Alkyldimethylbenzylammonium Chloride"/>
        <div>
            <Body 
                subHeader="GENERAL DESCRIPTION"
                firstParagraph="Benzalkonium chloride (BZK, BKC, BAK, BAC), juga dikenal sebagai alkyldimethylbenzylammonium chloride (ADBAC) dan dengan nama dagang Zephiran, adalah jenis surfaktan kationik. Ini adalah garam organik yang tergolong sebagai senyawa amonium kuaterner. ADBAC memiliki tiga kategori penggunaan utama: sebagai biokida, surfaktan kationik, dan agen transfer fase. ADBAC adalah campuran alkilbenzyldimethylammonium chlorides, di mana kelompok alkil memiliki berbagai panjang rantai alkil yang berbilangan genap."
            />
        </div>
        <section className='bg-dark-blue py-2'>
          <div className='container-screen'>
            <p className='font-sairaCondense text-white text-xl sm:text-5xl underline underline-offset-8 text-center mt-3 mb-7'>SPECIFICATION</p>
            <table className='border-collapse border border-white mx-auto text-white mb-3'>
              <thead>
                <tr>
                  <th></th>
                  <th className='border border-white'>50%</th>
                  <th className='border border-white'>80%</th>
                </tr>
              </thead>
             <tbody>
             <tr>
                <td className='border border-white px-2 font-bold'>Appearance</td>
                <td className='border border-white px-2'>Colorless liquid</td>
                <td className='border border-white px-2'>Colorless liquid</td>
              </tr>
              <tr>
                <td className='border border-white px-2 font-bold'>Active Content, %</td>
                <td className='border border-white px-2'>48 - 52</td>
                <td className='border border-white px-2'>78 - 82</td>
              </tr>
              <tr>
                <td className='border border-white px-2 font-bold'>Amine Salt, %</td>
                <td className='border border-white px-2'>2.0 max</td>
                <td className='border border-white px-2'>2.0 max</td>
              </tr>
              <tr>
                <td className='border border-white px-2 font-bold'>pH (1% Water Solution)</td>
                <td className='border border-white px-2'>6.0 ~ 8.0</td>
                <td className='border border-white px-2'>6.0 ~ 8.0</td>
              </tr>
             </tbody>  
            </table>
          </div>
        </section>

        <section className=''>
        <div className='container-screen'>
          <p className='sub-header-left'>APPLICATION</p>
          <div className='flex relative'>
          <div className='w-[75%]'>
            {application.map((item, index) => (
              <div key={index} className='my-7'>
                <p className='font-semibold text-body-blue'>{item.title}</p>
                <p className='text-body-blue'>{item.text}</p>
              </div>
            ))}
             <p className='font-semibold text-body-blue'>6. BENZALKONIUM CHLORIDE:</p>
             <p className='text-body-blue'>Merupakan bahan aktif dalam banyak produk konsumen:</p>
             <ul className='list-disc ml-6 text-body-blue mb-7'>
                <li>Produk farmasi seperti tetes atau semprotan mata, telinga, dan hidung, sebagai pengawet.</li>
                <li>Produk perawatan pribadi seperti hand sanitizer, tisu basah, sampo, deodoran, dan kosmetik.</li>
                <li>Antiseptik kulit, seperti Bactine dan Dettol.</li>
             </ul>         
          </div>
          <img src={Lab} className='w-1/4 absolute bottom-0 right-0'></img>
        </div>
          </div>
          
      </section>

    </div>
  )
}

export default Benzalkonium