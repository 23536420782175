import React,{ useState } from 'react'
import { ChevronRightIcon} from '@heroicons/react/24/outline'

const Body = (props) => {
const slides = [props.firstParagraph, props.secondParagraph, props.thirdParagraph]
const [page, setPage] = useState(0)

const next = () => {
    if(props.thirdParagraph === undefined){
        if (page < 1 ) {
            setPage(page + 1);
        } else {
            setPage(0);
        }
    }else if(props.thirdParagraph !== undefined){
        if (page < slides.length - 1) {
            setPage(page + 1);
        } else {
            setPage(0);
        }
    }
   
};

  return (
    <div>
        <div className='container-screen py-10 hidden sm:block'>
            <div className='sub-header-left'>
                {props.subHeader}
            </div>
            <div className='body-text'>
                {props.firstParagraph}
            </div>
            <div className='body-text'>
                {props.secondParagraph}
            </div>
            <div className='body-text'>
                {props.thirdParagraph}
            </div>
        </div>

        <div className='container-screen block sm:hidden'>
            <div className='sub-header-left'>
                {props.subHeader}
            </div>
            <div className='body-text h-[350px]'>
                {slides[page]}
            </div>
            {props.secondParagraph ? (
                <div className='flex justify-between mb-3 items-center'>
                <p className='text-slate-400 border-b-2 text-sm py-2'>Page {page + 1}</p>
                <button
                onClick={() => next()}
                className='bg-slate-200 hover:bg-dark-blue hover:text-white animation duration-150 rounded-lg p-1'
                >
            
                    <ChevronRightIcon className='w-7' />
                </button>
            </div>
            ):<></>} 
        </div>
    </div>
  )
}

export default Body