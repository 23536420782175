import React from 'react'
import { Navbar, Banner, Body } from '../component'
import Bannerf from '../assets/banner_formalin.jpg'


const Formalin = () => {
const backgroundImageStyle = {
    backgroundImage: `url(${Bannerf})`,
    };

const application = [
  {
    title: "1. INDUSTRI KAYU:",
    text: "Digunakan dalam formulasi perekat untuk membuat kayu lapis dan papan partikel."
  },
  {
    title: "2. INDUSTRI TEKSTIL:",
    text: "Digunakan dalam produksi tekstil tahan kerut."
  },
  {
    title: "3. INDUSTRI OTOMOTIF:",
    text: "Digunakan dalam pembuatan kampas rem dan cakram kopling."
  },
  {
    title: "4. INDUSTRI KONSTRUKSI:",
    text: "Ditemukan dalam bahan isolasi seperti papan busa dan serat kaca."
  },
  {
    title: "5. INDUSTRI FARMASI:",
    text: "Digunakan dalam sintesis beberapa obat dan bahan kimia."
  },
  {
    title: "6. INDUSTRI KOSMETIK:",
    text: "Ada dalam beberapa kosmetik dan produk perawatan pribadi sebagai pengawet."
  },
  {
    title: "7. INDUSTRI KESEHATAN:",
    text: "Digunakan untuk sterilisasi peralatan medis dan sebagai disinfektan."
  },
  {
    title: "8. INDUSTRI KIMIA:",
    text: "Sebagai intermediate dalam produksi berbagai bahan kimia dan resin."
  },
  {
    title: "9. INDUSTRI MAKANAN:",
    text: "Kadang digunakan sebagai pengawet makanan, meskipun regulasi membatasi penggunaannya."
  },
  {
    title: "10. INDUSTRI PLASTIK:",
    text: "Digunakan dalam produksi plastik, termasuk poliuretan."
  },
  {
    title: "11. LAYANAN PEMAKAMAN:",
    text: "Digunakan dalam cairan pengawet untuk preservasi jenazah."
  },
  
]
  return (
    <div>
        <Navbar />
        <Banner background={backgroundImageStyle} name="FORMALIN" clasname="font-sairaCondense text-white text-3xl sm:text-6xl" />
        <div>
            <Body 
                subHeader="GENERAL DESCRIPTION"
                firstParagraph="Formaldehida (formalin) diproduksi melalui oksidasi metanol. Terdiri dari 37% formaldehida dan zat-zat pengotor seperti metanol, sedikit asam format, aldehida, dan keton. Digunakan sebagai bahan pengubah dalam elektroforesis gel formaldehida-agarosa dari RNA."
                secondParagraph="Larutan formaldehida telah digunakan sebagai agen fiksasi untuk mengamankan sel selama pencitraan imunofluoresensi dan untuk menghubungkan silang sel selama pengujian presipitasi imunokromatin (ChIP).

                Larutan formaldehida telah digunakan untuk penghubungan silang/fiksasi sel dalam ChIP (presipitasi imunokromatin)."
            />
        </div>

        
      <section className=''>
        <div className='container-screen '>
          <p className='sub-header-left border-l-dark-blue'>APPLICATION</p>
          <div className='flex relative'>
            <div className='w-[75%]'>
              {application.map((item, index) => (
                <div key={index} className='my-7'>
                  <p className='font-semibold text-body-blue'>{item.title}</p>
                  <p className='text-body-blue'>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Formalin