import React from 'react'
import { Navbar } from '../component'
import Bannerf from '../assets/banner_belerang.jpg'
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid"
import Whatsapp from '../assets/whatsapp.svg'

const Contact = () => {
 
  return (
    <div>
        <Navbar />
        <div className='h-[10rem] sm:h-[10rem] flex items-center bg-right bg-cover bg-dark-blue'>
          <div className='container-screen flex flex-col items-center sm:items-start'>
              <p className="font-sairaCondense text-white text-3xl sm:text-6xl text-center">CONTACT US</p>
          </div>
        </div>
        <div className='container-screen sm:block hidden'>
          <p className='sub-header-left mt-7'>Contact Person</p>
          <ul className='list-disc text-body-blue ml-4 mb-10'>
            <li>Maya / Dewi</li>
            <div className='flex'>
              <PhoneIcon className='w-5 mr-3' />
              +62-812-9421-3544
            </div>          
          </ul>

          <p className='sub-header-left'>Address</p>
          <ul className='list-disc text-body-blue ml-4'>
            <li>Komplek Perkantoran PIK, B-20 <br/>Kapuk, Jakarta Utara</li>    
          </ul>

          <p className='sub-header-left'>Email</p>
          <ul className='list-disc text-body-blue ml-4'>
          <div className='flex items-center mb-7 mt-3'>
            <li><EnvelopeIcon className='w-6 mr-3' /></li>       
              aryndomulyasakti@gmail.com
            </div>           
          </ul>
        </div>

        {/* mobile version */}
        <div className='container-screen sm:hidden block'>
          <p className='sub-header-left mt-7'>Contact Person</p>
          <div className='flex flex-col items-center justify-center text-body-blue'> 
            <p className='font-semibold'>Maya / Dewi</p>
            <p>+62-812-9421-3544</p>
            <p>021-55964853</p>
          </div>

          <p className='sub-header-left mt-7'>Address</p>
          <div className='flex flex-col items-center justify-center text-body-blue'> 
            <p>Komplek Perkantoran PIK, B-20</p>
            <p>Kapuk, Jakarta Utara</p>
          </div>

          <p className='sub-header-left mt-7'>Email</p>
          <div className='flex flex-col items-center justify-center text-body-blue'> 
            <p className='mb-7'> aryndomulyasakti@gmail.com</p>
          </div>

        </div>
    </div>
  )
}

export default Contact;
