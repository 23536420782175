import './index.css';
import { Home, Formalin, Benzalkonium, Belerang, Citric, OtherProducts, Contact } from './page'
import { Footer } from './component'
import { Routes, Route} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route 
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />,
        <Route
          path="/formalin"
          element={
            <>
              <Formalin />
            </>
          }
        />
        <Route
          path="/benzalkonium"
          element={
            <>
              <Benzalkonium />
            </>
          }
        />
        <Route
          path="/belerang"
          element={
            <>
              <Belerang />
            </>
          }
        />
        <Route
          path="/citric"
          element={
            <>
              <Citric />
            </>
          }
        />
        <Route
          path="/other"
          element={
            <>
              <OtherProducts />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
