import React from 'react'
import { Navbar, Banner, Body } from '../component'
import Bannerf from '../assets/banner_citric.jpg'


const Citric = () => {
const backgroundImageStyle = {
    backgroundImage: `url(${Bannerf})`,
    };
    const application = [
      {
        title: "1. INDUSTRI MAKANAN DAN MINUMAN:",
        text: "Digunakan sebagai pengawet alami, pengatur keasaman, dan penyedap rasa dalam makanan dan minuman."
      },
      {
        title: "2. INDUSTRI FARMASI:",
        text: "Digunakan dalam produksi obat-obatan dan suplemen makanan."
      },
      {
        title: "3. INDUSTRI KOSMETIK:",
        text: "Ditemukan dalam produk perawatan kulit, produk kecantikan, dan produk perawatan pribadi lainnya."
      },
      {
        title: "4. INDUSTRI KIMIA:",
        text: "Digunakan sebagai bahan kimia dalam berbagai proses kimia dan reaksi."
      },
      {
        title: "5. INDUSTRI PEMBERSIH RUMAH TANGGA:",
        text: "Digunakan dalam produk pembersih rumah tangga sebagai agen penghilang noda."
      },
      {
        title: "6. INDUSTRI FARMASI:",
        text: "Digunakan dalam pembuatan tablet obat sebagai bahan pengikat."
      },
      {
        title: "7. INDUSTRI MINUMAN KESEHATAN:",
        text: "Digunakan dalam produksi minuman energi dan minuman olahraga. Asam sitrat monohidrat adalah bahan umum digunakan karena sifat-sifatnya yang aman dan multifungsi dalam berbagai aplikasi industri."
      },
      
      
    ]
  return (
    <div>
        <Navbar />
        <Banner background={backgroundImageStyle} name="CITRIC ACID" name2="MONOHYDRATE" clasname="font-sairaCondense text-white text-3xl sm:text-6xl" />
        <div>
            <Body 
                subHeader="GENERAL DESCRIPTION"
                firstParagraph="Asam sitrat adalah asam organik lemah. Ini adalah pengawet alami dan juga digunakan untuk memberikan rasa asam atau masam pada makanan dan minuman ringan. Dalam biokimia, basa konjugat dari asam sitrat, sitrat, penting sebagai perantara dalam siklus asam sitrat dan oleh karena itu terjadi dalam metabolisme hampir semua makhluk hidup."
                secondParagraph="Ini berupa bubuk kristal tak berwarna atau putih dan utamanya digunakan sebagai asidulan, perasa, dan pengawet dalam makanan dan minuman. Ini juga digunakan sebagai antioksidan, plastisizer, dan bahan pembersih, pengikat. 
                Biasanya digunakan dalam perdagangan makanan dan minuman sebagai agen rasa asam, agen perasa, antiseptik, serta agen anti-pengerasan."
            />
        </div>
        <section className=''>
        <div className='container-screen '>
          <p className='sub-header-left border-l-dark-blue'>APPLICATION</p>
          <div className='flex relative'>
            <div className='w-[75%]'>
              {application.map((item, index) => (
                <div key={index} className='my-7'>
                  <p className='font-semibold text-body-blue'>{item.title}</p>
                  <p className='text-body-blue'>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Citric