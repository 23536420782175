import React from 'react'
import { Navbar, Banner, Body } from '../component'
import Bannerf from '../assets/banner_belerang.jpg'
import Drum from '../assets/chemical drum.jpg'
import { Footer } from '../component'

const OtherProducts = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Bannerf})`,
  };

  const hexagonStyle = {
    clipPath: 'polygon(25% 0%, 205% 0%, 100% 50%, 205% 100%, 25% 100%, 0% 50%)',
    borderRadius: '100px !important', // Adjust the border radius as needed
  };
  
  
  
  return (
    <div>
        <Navbar />
        <div className='h-[10rem] sm:h-[10rem] flex items-center bg-right bg-cover bg-dark-blue'>
        <div className='container-screen flex flex-col items-center sm:items-start'>
            <p className="font-sairaCondense text-white text-3xl sm:text-6xl text-center">OTHER PRODUCTS</p>
        </div>
        </div>
        <div className='flex'>
          <div className='sm:container-screen'>
            <div className='block h-[4rem] sm:hidden overflow-hidden bg-bottom'>
              <img 
                src={Drum}
              />
            </div>
            <div className='container-screen relative sm:container py-7'>
              <p className='sub-header-left my-4'>PRODUCT LIST</p>
              <div>
                <ul className='list-disc ml-6 text-body-blue mb-7'>
                  <li>FORMALIN 37%</li>
                  <li>BKC-50</li>
                  <li>CITRIC ACID MONOHYDRATE</li>
                  <li>BELERANG</li>
                  <li>KAPORIT</li>
                  <li>VITAMIN C UNCOATED / WEISHENG BRAND</li>
                  <li>ALKOHOL TECHNICAL 70%, 90%, 99%</li>
                  <li>METHANOL</li>
                  <li>IPA</li>
                  <li>LYSOL</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='hidden sm:block'>
            <img 
              src={Drum}
              className=' absolute right-0   my-3 w-1/3'
              style={hexagonStyle}
            />
          </div>
        </div>
    </div>
  )
}

export default OtherProducts;
