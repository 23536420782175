import React from 'react'
import { Navbar, Banner, Body } from '../component'
import Bannerf from '../assets/banner_belerang.jpg'
import Lab from '../assets/lab.png'

const Belerang = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Bannerf})`,
  };
  const application = [
    {
      title: "1. INDUSTRI KIMIA:",
      text: "Digunakan dalam produksi asam sulfat, yang merupakan bahan kimia penting dalam banyak aplikasi industri lainnya."
    },
    {
      title: "2. INDUSTRI PUPUK:",
      text: "Digunakan dalam pembuatan pupuk fosfat super sebagai komponen utama."
    },
    {
      title: "3. INDUSTRI FARMASI:",
      text: "Digunakan dalam produksi obat-obatan dan sulfonamida."
    },
    {
      title: "4. INDUSTRI MINYAK DAN GAS:",
      text: "Digunakan dalam proses pemurnian minyak mentah dan gas alam."
    },
    {
      title: "5. INDUSTRI KARET DAN PLASTIK:",
      text: "Ditemukan dalam beberapa karet sintetis dan plastik untuk meningkatkan sifat fisik."
    },
    {
      title: "6. INDUSTRI LOGAM:",
      text: "Digunakan dalam proses pelindian dan pemisahan logam dari bijihnya."
    },
    {
      title: "7. INDUSTRI KERTAS:",
      text: "Digunakan dalam pembuatan kertas untuk memutihkan dan menghilangkan lignin."
    },
    {
      title: "8. INDUSTRI MAKANAN:",
      text: "Digunakan dalam produksi makanan sebagai pemanis untuk makanan tertentu."
    },
    {
      title: "9. INDUSTRI PERTANIAN:",
      text: "Digunakan sebagai fungisida dan pestisida untuk melawan penyakit dan hama."
    },
    {
      title: "10. INDUSTRI BATERAI:",
      text: "Ditemukan dalam beberapa jenis baterai, seperti baterai timbal-asam."
    },
  ]
  
  return (
    <div>
        <Navbar />
        <Banner background={backgroundImageStyle} name="BELERANG" clasname="font-sairaCondense text-white text-3xl sm:text-6xl" />
        <div>
            <Body 
                subHeader="GENERAL DESCRIPTION"
                firstParagraph="Asam sitrat adalah asam organik lemah. Ini adalah pengawet alami dan juga digunakan untuk memberikan rasa asam atau masam pada makanan dan minuman ringan. Dalam biokimia, basa konjugat dari asam sitrat, sitrat, penting sebagai perantara dalam siklus asam sitrat dan oleh karena itu terjadi dalam metabolisme hampir semua makhluk hidup."
                secondParagraph="Ini berupa bubuk kristal tak berwarna atau putih dan utamanya digunakan sebagai asidulan, perasa, dan pengawet dalam makanan dan minuman. Ini juga digunakan sebagai antioksidan, plastisizer, dan bahan pembersih, pengikat. Biasanya digunakan dalam perdagangan makanan dan minuman sebagai agen rasa asam, agen perasa, antiseptik, serta agen anti-pengerasan."
            />
        </div>
        <section className=''>
        <div className='container-screen '>
          <p className='sub-header-left border-l-dark-blue'>APPLICATION</p>
          <div className='flex relative'>
            <div className='w-[75%]'>
              {application.map((item, index) => (
                <div key={index} className='my-7'>
                  <p className='font-semibold text-body-blue'>{item.title}</p>
                  <p className='text-body-blue'>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Belerang